import { networkAction } from "helpers/network/networkAction";

import {
  GET_DIVISIONS,
  GET_DIVISION_BY_ID,
  DELETE_DIVISION,
  UPDATE_DIVISION,
  CREATE_DIVISION,
} from "constants/divisions";

import * as Api from "api";

export const getDivisions = () => async (dispatch) =>
  networkAction(dispatch, GET_DIVISIONS, Api.getDivisions, []);

export const getDivisionById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_DIVISION_BY_ID, Api.getDivisionById, [id]);

export const deleteDivision = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_DIVISION, Api.deleteDivision, [id]);

export const createDivision = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_DIVISION, Api.createDivision, [body]);

export const updateDivision = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_DIVISION, Api.updateDivision, [id, body]);
