import { Divider } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import BackupIcon from "@material-ui/icons/Backup";
import {
  default as Close,
  default as CloseIcon,
} from "@material-ui/icons/Close";
// helpers
import { getJsonFromUrl } from "helpers";
import CheckboxAutocomplete from "hooks/CheckboxAutocomplete";
import ImageCrop from "hooks/ImageCrop";
import RichText from "hooks/RichText";
import ImageResize from "image-resize";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactSelect from "react-select";
// constants
import defaultBody from "./defaultBody";
// styles
import styles from "./styles";

class ProgramEdit extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.func,
    refreshKey: PropTypes.number,
    api: PropTypes.object,
    program: PropTypes.object,
    ages: PropTypes.array,
    programTypes: PropTypes.array,
    grades: PropTypes.array,
    levels: PropTypes.array,
    instruments: PropTypes.array,
    musicalFamilies: PropTypes.array,
    contentLoading: PropTypes.bool,
    divisions: PropTypes.array,
    institutions: PropTypes.array,
    academies: PropTypes.array,
    programSubcategories: PropTypes.array,
    masterTags: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    UploadCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { program } = this.props;
    const { UploadCenter } = this.context;
    this.state = defaultBody;
    if (program && program.id) {
      this.state = { ...program };
    }
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      () => console.log("upload callback"),
      undefined
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.program && nextProps.program.id) {
      this.setState({
        ...nextProps.program,
      });
    }
  }

  userDidDrop(files) {
    this.setState({ files });
  }

  async upload() {
    const { UploadCenter } = this.context;
    const { files } = this.state;
    for (const key in files) {
      if (files.hasOwnProperty(key)) {
        const file = files[key];
        UploadCenter.upload(file).then((r) => {
          console.log(r);
          debugger;
          this.setState({ picture: r.payload }, () => this.save("picture"));
        });
      }
    }
  }

  handleChange = (name) => (event) => {
    //eslint-disable-line
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  async save(name, numeric) {
    const { api, refresh, program } = this.props;

    if (program[name] === this.state[name]) return;

    this.setState({
      loading: true,
    });

    await api.updateProgram(program.id, {
      [name]: numeric ? Number(this.state[name]) : this.state[name],
    });

    this.setState({
      loading: false,
    });

    refresh();
  }

  initState() {
    this.setState(...defaultBody);
  }

  async addProgramInstrument(instrument) {
    const { api, program, refresh } = this.props;
    await api.createProgramInstrument({
      instrumentID: instrument.id,
      programID: program.id,
    });

    refresh();
  }

  async deleteProgramInstrument(programInstrument) {
    const { api, refresh } = this.props;
    await api.deleteProgramInstrument(programInstrument.relationID);

    refresh();
  }

  async addProgramMusicalFamily(musicalFamily) {
    const { api, program, refresh } = this.props;
    await api.createProgramMusicalFamily({
      musicalFamilyID: musicalFamily.id,
      programID: program.id,
    });

    refresh();
  }

  async deleteProgramMusicalFamily(musicalFamily) {
    const { api, refresh } = this.props;
    await api.deleteProgramMusicalFamily(musicalFamily.relationID);

    refresh();
  }

  async addProgramLevel(level) {
    const { api, program, refresh } = this.props;
    await api.createProgramLevel({
      levelID: level.id,
      programID: program.id,
    });

    refresh();
  }

  async deleteProgramLevel(level) {
    const { api, refresh } = this.props;
    await api.deleteProgramLevel(level.relationID);

    refresh();
  }

  async addProgramDivision(division) {
    const { api, program, refresh } = this.props;
    await api.createProgramDivision({
      divisionID: division.id,
      programID: program.id,
    });

    refresh();
  }

  async addProgramRelateSubcategory(programSubcategory) {
    const { api, program, refresh } = this.props;
    await api.createProgramRelateSubcategory({
      programSubcategoryID: programSubcategory.id,
      programID: program.id,
    });

    refresh();
  }

  async addProgramTag(masterTag) {
    const { api, program, refresh } = this.props;
    await api.createProgramTag({
      tagID: masterTag.id,
      programID: program.id,
    });

    refresh();
  }

  async deleteProgramDivision(division) {
    const { api, refresh } = this.props;
    await api.deleteProgramDivision(division.relationID);

    refresh();
  }

  async deleteProgramRelateSubcategory(programRelateSubcategory) {
    const { api, refresh } = this.props;
    await api.deleteProgramRelateSubcategory(
      programRelateSubcategory.relationID
    );

    refresh();
  }

  async deleteProgramTag(masterTag) {
    const { api, refresh } = this.props;
    await api.deleteProgramTag(masterTag.relationID);

    refresh();
  }

  async deleteProgramInstitution(institution) {
    const { api, refresh } = this.props;
    await api.deleteProgramInstitution(institution.relationID);

    refresh();
  }

  async addProgramInstitution(institution) {
    const { api, program, refresh } = this.props;
    await api.createProgramInstitution({
      institutionID: institution.id,
      programID: program.id,
    });

    refresh();
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle: "Please confirm your action.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
          callback: () => console.log("Callback"),
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { api, refresh, program, history } = this.props;

    const { NotificationCenter } = this.context;
    const resp = await api.deleteProgram(program.id);
    if (resp.success) {
      const urlParams = getJsonFromUrl(window.location);
      delete urlParams.programID;
      const search = Object.keys(urlParams)
        .map(
          (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
        )
        .join("&");
      history.push({
        search,
      });
      NotificationCenter.sweetAlert({
        title: "Program has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      program,
      ages,
      programTypes,
      grades,
      levels,
      instruments,
      divisions,
      institutions,
      contentLoading,
      musicalFamilies,
      history,
      academies,
      programSubcategories,
      masterTags,
    } = this.props;

    const { UploadCenter } = this.context;

    const {
      programTypeID,
      name,
      website,
      dateFrom,
      dateTo,
      instrumentEl,
      divisionEl,
      institutionEl,
      musicalFamiliesEl,
      contact,
      levelEl,
      ageFromID,
      ageToID,
      gradeToID,
      gradeFromID,
      academyID,
      email,
      phone,
      address,
      zipcode,
      city,
      state,
      country,
      locationSpecific,
      nominationRequired,
      membershipRequired,
      otherCriteriaRequired,
      musicSpecific,
      otherCriteria,
      updatedAt,
      picture,
      details,
      description,
      subCategories,
      tags,
    } = this.state;

    if (contentLoading) {
      return (
        <Grid
          container
          className={classes.container}
          justify="center"
          alignItems="center"
        >
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    return (
      <div className={classes.container}>
        <Grid container spacing={2} justify="space-between">
          <Grid item>
            <Typography
              display="block"
              variant="h5"
              style={{ fontWeight: 900 }}
            >
              Edit Program
            </Typography>
          </Grid>
          {history ? (
            <Grid item>
              <Chip
                label="Close"
                onClick={() => {
                  const urlParams = getJsonFromUrl(window.location);
                  delete urlParams.programID;
                  const search = Object.keys(urlParams)
                    .map(
                      (k) =>
                        `${encodeURIComponent(k)}=${encodeURIComponent(
                          urlParams[k]
                        )}`
                    )
                    .join("&");
                  history.push({
                    search,
                  });
                }}
                avatar={
                  <Avatar style={{ background: "none" }}>
                    <Close />
                  </Avatar>
                }
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary" display="block">
              Last checked:{" "}
              {updatedAt ? moment(updatedAt).format("lll") : "Not set"}
            </Typography>
            <Typography
              style={{ textDecoration: "underline", cursor: "pointer" }}
              variant="caption"
              color="textSecondary"
              onClick={() => {
                this.setState({ updatedAt: moment().utc().format() }, () => {
                  this.save("updatedAt");
                });
              }}
            >
              Mark as updated today
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip
              label="Upload Logo"
              size="small"
              icon={<BackupIcon />}
              onClick={UploadCenter.openFileDialog}
            />
          </Grid>
          {picture ? (
            <Grid item xs={12}>
              <img width="100px" src={picture} />
              <br />
              <Chip
                label="Remove Logo"
                size="small"
                icon={<CloseIcon />}
                onClick={() => {
                  this.setState({ picture: "" }, () => this.save("picture"));
                }}
              />
              <ImageCrop
                value={picture}
                onCrop={(url) => {
                  var imageResize = new ImageResize({
                    format: "png",
                    width: 100,
                    height: 100,
                  });
                  imageResize.play(url).then(async (resized) => {
                    fetch(resized)
                      .then((res) => res.blob())
                      .then(async (blob) => {
                        const resp = await UploadCenter.upload(blob);
                        if (resp.success) {
                          this.setState({ picture: resp.payload }, () =>
                            this.save("picture")
                          );
                        }
                      });
                  });
                }}
              />
              <TextField
                id="picture"
                className={classes.textField}
                value={picture}
                onChange={this.handleChange("picture")}
              />
            </Grid>
          ) : (
            []
          )}
          <Grid item xs={12}>
            <Typography
              display="block"
              color="primary"
              variant="subheading"
              style={{ fontWeight: 800 }}
            >
              Academy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ReactSelect
              value={{
                value: academies && academies.find((a) => a.id === academyID),
                label:
                  academies &&
                  academies.find((a) => a.id === academyID) &&
                  academies.find((a) => a.id === academyID)?.name,
              }}
              placeHolder={"Select the GL Account"}
              isClearable
              onChange={(e) => {
                this.state.academyID = e && e.value ? e.value.id : undefined;
                this.save("academyID");
              }}
              options={
                academies &&
                academies.map((a) => ({
                  value: a,
                  label: a.name,
                }))
              }
            />
          </Grid>
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Typography
              display="block"
              color="primary"
              variant="subheading"
              style={{ fontWeight: 800 }}
            >
              Type & Divisions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="group-simple">Program Type</InputLabel>
              <Select
                value={programTypeID}
                onChange={(e) => {
                  this.state.programTypeID = e.target.value;
                  this.save("programTypeID");
                  this.setState({
                    divisions: [],
                  });
                }}
              >
                {programTypes.map((c) => (
                  <MenuItem key={`programType_${c.id}`} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <CheckboxAutocomplete
              options={programSubcategories?.filter(
                (c) => c.programTypeID === programTypeID
              )}
              value={
                subCategories?.filter(
                  (c) => c.programTypeID === programTypeID
                ) ?? []
              }
              label="Subcategories"
              optionKey="name"
              // groupBy={(option) => option.categoryName}
              onDelete={(e) => {
                this.deleteProgramRelateSubcategory(e);
              }}
              onAdd={(e) => {
                this.addProgramRelateSubcategory(e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxAutocomplete
              options={masterTags}
              value={tags ?? []}
              label="Master Tags"
              optionKey="name"
              // groupBy={(option) => option.categoryName}
              onDelete={(e) => {
                this.deleteProgramTag(e);
              }}
              onAdd={(e) => {
                this.addProgramTag(e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {/* <Grid item xs={12}>
            <Chip
              label="+ Institution"
              aria-owns={divisionEl ? "simple-menu" : undefined}
              onClick={(e) => this.setState({ institutionEl: e.currentTarget })}
              style={{ marginBottom: 8 }}
              variant="outlined"
            />
            <Menu
              id="simple-menu"
              anchorEl={institutionEl}
              open={Boolean(institutionEl)}
              onClose={() => this.setState({ institutionEl: null })}
            >
              {institutions
                ?.filter(
                  (i) =>
                    this.state.institutions.findIndex(
                      (si) => si.id === i.id
                    ) === -1
                )
                .map((i) => (
                  <MenuItem
                    onClick={() => {
                      this.addProgramInstitution(i);
                      this.setState({ institutionEl: null });
                    }}
                    key={`instituToAdd_${i}`}
                  >
                    {i.name}
                  </MenuItem>
                ))}
            </Menu>
            <Grid container spacing={1}>
              {this.state.institutions &&
                this.state.institutions.map((insti) => (
                  <Grid item>
                    <Chip
                      label={insti.name}
                      onDelete={() => this.deleteProgramInstitution(insti)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            <CheckboxAutocomplete
              options={divisions?.filter((d) => d.programTypeID === 13)}
              value={this.state.divisions?.filter(
                (f) => f.programTypeID === 13
              )}
              label="Undergraduate Degrees"
              optionKey="name"
              groupBy={(option) => option.categoryName}
              onDelete={(e) => {
                this.deleteProgramDivision(e);
              }}
              onAdd={(e) => {
                this.addProgramDivision(e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxAutocomplete
              options={divisions?.filter((d) => d.programTypeID === 14)}
              value={this.state.divisions?.filter(
                (f) => f.programTypeID === 14
              )}
              label="Undergraduate Majors"
              optionKey="name"
              groupBy={(option) => option.categoryName}
              onDelete={(e) => {
                this.deleteProgramDivision(e);
              }}
              onAdd={(e) => {
                this.addProgramDivision(e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxAutocomplete
              options={divisions?.filter((d) => d.programTypeID === 15)}
              value={this.state.divisions?.filter(
                (f) => f.programTypeID === 15
              )}
              label="Graduate Degrees"
              optionKey="name"
              groupBy={(option) => option.categoryName}
              onDelete={(e) => {
                this.deleteProgramDivision(e);
              }}
              onAdd={(e) => {
                this.addProgramDivision(e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxAutocomplete
              options={divisions?.filter((d) => d.programTypeID === 16)}
              value={this.state.divisions?.filter(
                (f) => f.programTypeID === 16
              )}
              label="Graduate Majors"
              optionKey="name"
              groupBy={(option) => option.categoryName}
              onDelete={(e) => {
                this.deleteProgramDivision(e);
              }}
              onAdd={(e) => {
                this.addProgramDivision(e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxAutocomplete
              options={divisions?.filter((d) => d.programTypeID === 17)}
              value={this.state.divisions?.filter(
                (f) => f.programTypeID === 17
              )}
              label="Certificates"
              optionKey="name"
              groupBy={(option) => option.categoryName}
              onDelete={(e) => {
                this.deleteProgramDivision(e);
              }}
              onAdd={(e) => {
                this.addProgramDivision(e);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <CheckboxAutocomplete
              options={divisions?.filter(
                (d) => d.programTypeID === programTypeID
              )}
              value={this.state.divisions?.filter(
                (f) => f.programTypeID === programTypeID
              )}
              label="Divisions"
              optionKey="name"
              groupBy={(option) => option.categoryName}
              onDelete={(e) => {
                this.deleteProgramDivision(e);
              }}
              onAdd={(e) => {
                this.addProgramDivision(e);
              }}
            />
          </Grid>
          {/* {programTypeID ? (
            <Grid item xs={12}>
              <Chip
                label="+ Division"
                aria-owns={divisionEl ? "simple-menu" : undefined}
                onClick={(e) => this.setState({ divisionEl: e.currentTarget })}
                style={{ marginBottom: 8 }}
                variant="outlined"
              />
              <Menu
                id="simple-menu"
                anchorEl={divisionEl}
                open={Boolean(divisionEl)}
                onClose={() => this.setState({ divisionEl: null })}
              >
                {divisions
                  ?.filter((d) => d.programTypeID === program.programTypeID)
                  ?.filter(
                    (i) =>
                      this.state.divisions.findIndex((si) => si.id === i.id) ===
                      -1
                  )
                  .map((i) => (
                    <MenuItem
                      onClick={() => {
                        this.addProgramDivision(i);
                        this.setState({ divisionEl: null });
                      }}
                      key={`instruToAdd_${i}`}
                    >
                      {i.name}
                    </MenuItem>
                  ))}
              </Menu>
              <Grid container spacing={1}>
                {this.state.divisions &&
                  this.state.divisions.map((divi) => (
                    <Grid item>
                      <Chip
                        label={divi.name}
                        onDelete={() => this.deleteProgramDivision(divi)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          ) : (
            []
          )} */}
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Typography
              display="block"
              color="primary"
              variant="subheading"
              style={{ fontWeight: 800 }}
            >
              About the Program
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Name"
              fullWidth
              value={name}
              onChange={this.handleChange("name")}
              onBlur={() => this.save("name")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("name");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Details"
              fullWidth
              value={details}
              multiline
              maxRows={10}
              onChange={this.handleChange("details")}
              onBlur={() => this.save("details")}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" color="textSecondary">
              Description
            </Typography>
            <RichText
              value={description}
              onSave={(v) =>
                this.setState({ description: v }, () =>
                  this.save("description")
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Website"
              fullWidth
              value={website}
              onChange={this.handleChange("website")}
              onBlur={() => this.save("website")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("website");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Date From"
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              value={dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : ""}
              onChange={this.handleChange("dateFrom")}
              onBlur={() => this.save("dateFrom")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("dateFrom");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Date To"
              InputLabelProps={{ shrink: true }}
              fullWidth
              type="date"
              value={dateTo ? moment(dateTo).format("YYYY-MM-DD") : ""}
              onChange={this.handleChange("dateTo")}
              onBlur={() => this.save("dateTo")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("dateTo");
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Typography
              display="block"
              color="primary"
              variant="subheading"
              style={{ fontWeight: 800 }}
            >
              Configuration
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="group-simple">Location Specific?</InputLabel>
              <Select
                value={locationSpecific || ""}
                onChange={(e) => {
                  this.state.locationSpecific = e.target.value;
                  this.save("locationSpecific");
                }}
              >
                <MenuItem value={-1}>Unkown</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="group-simple">
                Nomination Required?
              </InputLabel>
              <Select
                value={nominationRequired || ""}
                onChange={(e) => {
                  this.state.nominationRequired = e.target.value;
                  this.save("nominationRequired");
                }}
              >
                <MenuItem value={-1}>Unkown</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="group-simple">
                Membership Required?
              </InputLabel>
              <Select
                value={membershipRequired || ""}
                onChange={(e) => {
                  this.state.membershipRequired = e.target.value;
                  this.save("membershipRequired");
                }}
              >
                <MenuItem value={-1}>Unkown</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="group-simple">Music Specific?</InputLabel>
              <Select
                value={musicSpecific || ""}
                onChange={(e) => {
                  this.state.musicSpecific = e.target.value;
                  this.save("musicSpecific");
                }}
              >
                <MenuItem value={-1}>Unkown</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel htmlFor="group-simple">
                Other Criteria Required?
              </InputLabel>
              <Select
                value={otherCriteriaRequired || ""}
                onChange={(e) => {
                  this.state.otherCriteriaRequired = e.target.value;
                  this.save("otherCriteriaRequired");
                }}
              >
                <MenuItem value={-1}>Unkown</MenuItem>
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {otherCriteriaRequired === 1 ? (
            <Grid item xs={12}>
              <TextField
                label="What Other Criteria?"
                InputLabelProps={{ shrink: true }}
                fullWidth
                value={otherCriteria}
                onChange={this.handleChange("otherCriteria")}
                onBlur={() => this.save("otherCriteria")}
                onKeyPress={(e) => {
                  if (e.key === "Enter") this.save("otherCriteria");
                }}
              />
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Typography
              display="block"
              color="primary"
              variant="subheading"
              style={{ fontWeight: 800 }}
            >
              Contact Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contact"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={contact}
              onChange={this.handleChange("contact")}
              onBlur={() => this.save("contact")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("contact");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Email"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={email}
              onChange={this.handleChange("email")}
              onBlur={() => this.save("email")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("email");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={phone}
              onChange={this.handleChange("phone")}
              onBlur={() => this.save("phone")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("phone");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Address"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={address}
              onChange={this.handleChange("address")}
              onBlur={() => this.save("address")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("address");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Zipcode"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={zipcode}
              onChange={this.handleChange("zipcode")}
              onBlur={() => this.save("zipcode")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("zipcode");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="City"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={city}
              onChange={this.handleChange("city")}
              onBlur={() => this.save("city")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("city");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="State"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={state}
              onChange={this.handleChange("state")}
              onBlur={() => this.save("state")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("state");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Country"
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={country}
              onChange={this.handleChange("country")}
              onBlur={() => this.save("country")}
              onKeyPress={(e) => {
                if (e.key === "Enter") this.save("country");
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Typography
              display="block"
              color="primary"
              variant="subheading"
              style={{ fontWeight: 800 }}
            >
              Instruments & Musical Families
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Chip
              label="+ Instrument"
              aria-owns={instrumentEl ? "simple-menu" : undefined}
              onClick={(e) => this.setState({ instrumentEl: e.currentTarget })}
              style={{ marginBottom: 8 }}
              variant="outlined"
            />
            <Menu
              id="simple-menu"
              anchorEl={instrumentEl}
              open={Boolean(instrumentEl)}
              onClose={() => this.setState({ instrumentEl: null })}
            >
              {instruments
                ?.filter(
                  (i) =>
                    this.state.instruments.findIndex((si) => si.id === i.id) ===
                    -1
                )
                .map((i) => (
                  <MenuItem
                    onClick={() => {
                      this.addProgramInstrument(i);
                      this.setState({ instrumentEl: null });
                    }}
                    key={`instruToAdd_${i}`}
                  >
                    {i.name}
                  </MenuItem>
                ))}
            </Menu>
            <Grid container spacing={1}>
              {this.state.instruments &&
                this.state.instruments.map((instru) => (
                  <Grid item>
                    <Chip
                      label={instru.name}
                      onDelete={() => this.deleteProgramInstrument(instru)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Chip
              label="+ Musical Family"
              aria-owns={musicalFamiliesEl ? "simple-menu" : undefined}
              onClick={(e) =>
                this.setState({ musicalFamiliesEl: e.currentTarget })
              }
              style={{ marginBottom: 8 }}
              variant="outlined"
            />
            <Menu
              id="simple-menu"
              anchorEl={musicalFamiliesEl}
              open={Boolean(musicalFamiliesEl)}
              onClose={() => this.setState({ musicalFamiliesEl: null })}
            >
              {musicalFamilies
                ?.filter(
                  (i) =>
                    this.state.musicalFamilies.findIndex(
                      (si) => si.id === i.id
                    ) === -1
                )
                .map((i) => (
                  <MenuItem
                    onClick={() => {
                      this.addProgramMusicalFamily(i);
                      this.setState({ musicalFamiliesEl: null });
                    }}
                    key={`musicalFamToAdd_${i}`}
                  >
                    {i.name}
                  </MenuItem>
                ))}
            </Menu>
            <Grid container spacing={1}>
              {this.state.musicalFamilies &&
                this.state.musicalFamilies.map((fam) => (
                  <Grid item>
                    <Chip
                      label={fam.name}
                      onDelete={() => this.deleteProgramMusicalFamily(fam)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Typography
              display="block"
              color="primary"
              variant="subheading"
              style={{ fontWeight: 800 }}
            >
              Ages
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="group-simple">From Age</InputLabel>
                  <Select
                    inputProps={{
                      name: "ageFromID",
                      id: "group-simple",
                    }}
                    value={ageFromID || ""}
                    onChange={(e) => {
                      this.state.ageFromID = e.target.value;
                      this.save("ageFromID");
                    }}
                  >
                    <MenuItem value={-1}>None</MenuItem>
                    {ages.map((a) => (
                      <MenuItem key={`ageFrom_${a.id}`} value={a.id}>
                        {a.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="group-simple">To Age</InputLabel>
                  <Select
                    inputProps={{
                      name: "To",
                      id: "group-simple",
                    }}
                    value={ageToID || ""}
                    onChange={(e) => {
                      this.state.ageToID = e.target.value;
                      this.save("ageToID");
                    }}
                  >
                    <MenuItem value={-1}>None</MenuItem>
                    {ages.map((a) => (
                      <MenuItem key={`ageTo_${a.id}`} value={a.id}>
                        {a.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="group-simple">From Grade</InputLabel>
                  <Select
                    inputProps={{
                      name: "From",
                      id: "group-simple",
                    }}
                    value={gradeFromID || ""}
                    onChange={(e) => {
                      this.state.gradeFromID = e.target.value;
                      this.save("gradeFromID");
                    }}
                  >
                    <MenuItem value={-1}>None</MenuItem>
                    {grades.map((g) => (
                      <MenuItem key={`gradeFrom_${g.id}`} value={g.id}>
                        {g.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel htmlFor="group-simple">To Grade</InputLabel>
                  <Select
                    inputProps={{
                      name: "To",
                      id: "group-simple",
                    }}
                    value={gradeToID || ""}
                    onChange={(e) => {
                      this.state.gradeToID = e.target.value;
                      this.save("gradeToID");
                    }}
                  >
                    <MenuItem value={-1}>None</MenuItem>
                    {grades.map((g) => (
                      <MenuItem key={`gradeTo_${g.id}`} value={g.id}>
                        {g.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.section} spacing={2}>
          <Grid item xs={12}>
            <Chip
              label="+ Level"
              aria-owns={levelEl ? "simple-menu" : undefined}
              onClick={(e) => this.setState({ levelEl: e.currentTarget })}
              style={{ marginBottom: 8 }}
              variant="outlined"
            />
            <Menu
              id="simple-menu"
              anchorEl={levelEl}
              open={Boolean(levelEl)}
              onClose={() => this.setState({ levelEl: null })}
            >
              {levels
                ?.filter(
                  (i) =>
                    this.state.levels.findIndex((si) => si.id === i.id) === -1
                )
                .map((i) => (
                  <MenuItem
                    onClick={() => {
                      this.addProgramLevel(i);
                      this.setState({ levelEl: null });
                    }}
                    key={`levelToAdd_${i}`}
                  >
                    {i.name}
                  </MenuItem>
                ))}
            </Menu>
            <Grid container spacing={2}>
              {this.state.levels &&
                this.state.levels.map((lev) => (
                  <Grid item>
                    <Chip
                      label={lev.name}
                      onDelete={() => this.deleteProgramLevel(lev)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
          <Grid container className={classes.section} spacing={2}>
            <Grid item xs={12}>
              <Typography
                display="block"
                variant="subheading"
                style={{ fontWeight: 800, color: "#f44336" }}
              >
                Danger Zone
              </Typography>
            </Grid>
            <Grid item>
              <Button
                style={{
                  background: "#f44336",
                  color: "white",
                }}
                onClick={this.delete.bind(this)}
              >
                Delete Program
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ProgramEdit);
